export const breadcrumbList = [
  {
    name: '员工管理',
    path: ''
  },
  {
    name: '员工目标',
    path: ''
  }
]

export const addtagetBreadcrumbList = [
  {
    name: '员工管理',
    path: ''
  },
  {
    name: '员工目标',
    path: '/main/target/targetCategory'
  },
  {
    name: '发起考核',
    path: ''
  }
]
export const edittagetBreadcrumbList = [
  {
    name: '员工管理',
    path: ''
  },
  {
    name: '员工目标',
    path: '/main/target/targetCategory'
  },
  {
    name: '考核信息',
    path: ''
  }
]
export const addKpi = [
  {
    name: '员工管理',
    path: ''
  },
  {
    name: '员工目标',
    path: '/main/target/targetCategory'
  },
  {
    name: '新增考核表',
    path: ''
  }
]

export const kpiDeatil = [
  {
    name: '员工管理',
    path: ''
  },
  {
    name: '员工目标',
    path: '/main/target/targetCategory'
  },
  {
    name: '考核表信息',
    path: ''
  }
]
export const areaList = [
  {
    label: '浦东',
    value: 1
  },
  {
    label: '浦西',
    value: 2
  }
]

export const rule = {
  name: [{ required: true, message: '请输入考核名称', trigger: 'blur' }],
  date: [{ required: true, message: '请选择月份', trigger: 'blur' }],
  roleId: [{ required: true, message: '请选择角色', trigger: 'blur' }],
  shopIds: [{ required: true, message: '请选择门店', trigger: 'blur' }],
  tableId: [{ required: true, message: '请选择考评表', trigger: 'blur' }]
}
export const kpiRule = {
  name: [{ required: true, message: '请输入考核表名称', trigger: 'blur' }],
  cycleType: [{ required: true, message: '请选择周期类型', trigger: 'change' }],
  roleId: [{ required: true, message: '请选择被考核人', trigger: 'change' }],
  itemList: [{ required: true, message: '请选择考核指标', trigger: 'change' }]
}
export const rules = {
  roleId: [{ required: true, message: '请选择指标分类', trigger: 'change' }],
  type: [{ required: true, message: '请选择指标类型', trigger: 'change' }],
  resultType: [{ required: true, message: '请选择结果值', trigger: 'change' }],
  resultId: [{ required: true, message: '请选择名称', trigger: 'change' }],
  name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
  remark: [{ required: true, message: '请输入说明', trigger: 'blur' }]
}
