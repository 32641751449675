<template>
  <div class="add-staff-dialog">
    <page-dialog width="600px" @changeDialogVisible="changeDialogVisible" :dialogVisible="dialogVisible" title="选择员工"
      :isShowFooter="true">
      <div class="content">
        <div class="left-item">
          <div class="form">
            <el-select size="small" clearable @change="positionChange" v-model="queryInfo.joblv" class="m-2 el-select-1"
              placeholder="所属职位">
              <el-option v-for="item in staffAllRankList" :key="item.lvId" :label="item.levelName" :value="item.lvId">
              </el-option>
            </el-select>
            <el-select size="small" clearable v-model="queryInfo.subjoblv" class="m-2" placeholder="所属职级">
              <el-option v-for="item in staffChildRankList" :key="item.lvId" :label="item.levelName" :value="item.lvId">
              </el-option>
            </el-select>
          </div>
          <div class="search">
            <el-input @change="handleSearchChange" clearable v-model="queryInfo.keyword" size="small"
              placeholder="搜索姓名手机号"></el-input>
            <el-button @click="handleSearchClick" size="small" plain class="search-btn" type="primary">搜索</el-button>
          </div>
          <div class="staff-list">
            <div class="tips">共{{ staffCount }}位员工</div>
            <el-scrollbar height="250px">
              <template :key="item" v-for="item in staffCopyList">
                <div :class="{ isActive: staffGroupValue.findIndex(ele => ele.userId == item.userId) != -1 }"
                  @click="handleStaffClick(item)" class="staff-item">
                  <div class="left">
                    <img :src="item.userPhoto" alt />
                    <div class="name">{{ item.userRealname }}</div>
                  </div>
                  <div class="right">
                  </div>
                </div>
              </template>
            </el-scrollbar>
          </div>
          <el-pagination v-model:currentPage="queryInfo.page" v-model:page-size="queryInfo.pageSize"
            :page-sizes="[10, 20, 30, 40]" :small="true" layout=" sizes, prev, pager, next" :total="staffCount"
            :pager-count="3" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
        </div>
        <div class="right-item">
          <div class="tips">已选员工</div>
          <div class="opt-staff-list">
            <template :key="item" v-for="item in staffGroupValue">
              <div class="opt-staff">
                <div class="name">{{ item.userRealname }}</div>
                <div @click="handleRemoveClick(item)" class="remove">移除</div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <template #footer>
        <el-button size="small" type="primary" @click="handleSaveClick">保存</el-button>
      </template>
    </page-dialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, watch, computed, onUnmounted } from 'vue'
import PageDialog from '@/components/page-dialog/page-dialog.vue'
import { useStore } from 'vuex'
import { COMPANY_ID } from '@/common/constant'
import { useState } from '@/hooks'
import { UPLOAD_BASE_URL } from '@/common/constant'
import emitter from '@/utils/eventbus'
import { isTeacherTarget } from '@/service/main/target'
import { ElMessage } from 'element-plus'

export default defineComponent({
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    currentRnid: {
      type: Number,
      default: -1
    },
    currentShopId: {
      type: Number,
      required: true
    }
  },
  components: {
    PageDialog
  },
  emits: ['changeDialogVisible', 'changeOptStaff'],
  setup(props, { emit }) {
    const store = useStore()
    const ruleForm = reactive({
      name: ''
    })
    const changeDialogVisible = (flag) => {
      initPage()
      emit('changeDialogVisible', flag)
    }

    const currentPage = reactive({
      page: 1,
      pageSize: 10
    })

    const handleSizeChange = (pageSize) => {
      currentPage.pageSize = pageSize
    }

    const handleCurrentChange = (page) => {
      currentPage.page = page
    }

    watch(currentPage, () => {
      initPage()
    }, {
      deep: true
    })

    const queryInfo = reactive({
      page: 1,
      pageszie: 10,
      status: 1,
      keyword: '',
      shopid: props.currentShopId,
      joblv: '',
      subjoblv: '',
      companyId: COMPANY_ID
    })

    watch(() => props.currentShopId, (n) => {
      queryInfo.shopid = n
    }, {
      deep: true,

    })

    const handleSearchClick = () => {
      store.dispatch('staff/getstaffListAction', { ...queryInfo })
    }

    const handleSearchChange = (value) => {
      if (value.length == 0) {
        store.dispatch('staff/getstaffListAction', { ...queryInfo })
      }
    }


    const searchValue = ref('')


    const handleStaffClick = async item => {
      const isHaveTarget =await isTeacherTarget(item.userId, 4)

      if (Number(isHaveTarget.data) > 0) {
        ElMessage({
          message: '该对象已存在目标!',
          type: 'warning',
        })
        return
      }
      const index = staffGroupValue.value.findIndex(ele => ele.userId == item.userId)
      if (index != -1) {
        staffGroupValue.value.splice(index, 1)
      } else {
        staffGroupValue.value.push(item)
      }
    }

    const initPage = () => {

      store.dispatch('staff/getstaffListAction', {
        ...queryInfo
      })

      store.dispatch('staff/getStaffAllRankListAction', COMPANY_ID)
    }

    const positionChange = (pid) => {
      if (String(pid).length == 0) return
      ruleForm.subjoblv = ''
      store.dispatch('staff/getStaffChildRankAction', pid)
    }


    watch(() => queryInfo.page, () => {
      store.dispatch('staff/getstaffListAction', { ...queryInfo })
    }, {
      deep: true
    })


    const staffList = computed(() => store.state.staff.staffList)
    const staffCopyList = ref([])
    const formState = useState('staff', ['staffCount'])
    const staffGroupValue = ref([])
    const currentOptStaffList = ref([])
    const handleCheckBoxChange = (value) => {
    }

    watch(staffList, (n) => {
      if (n != null) {
        staffCopyList.value = n
      }
    })

    const handleRemoveClick = (item) => {
      const index = staffGroupValue.value.findIndex(val => val.userId == item.userId)
      staffGroupValue.value.splice(index, 1)
    }

    const staffAllRankList = computed(() => store.state.staff.staffAllRankList)
    const staffChildRankList = computed(() => store.state.staff.staffChildRankList)


    const jobLevel = ref('')
    const handleSaveClick = () => {
      let staffList = staffGroupValue.value
      let staffId = []
      let staffName = []
      staffList.forEach((item, index) => {
        staffId.push(item.userId)
        staffName.push(item.userRealname)
      })
      emit('changeOptStaff', {
        staffId,
        staffName
      })
      emit('changeDialogVisible', false)
    }


    return {
      positionChange,
      staffAllRankList,
      staffChildRankList,
      handleSearchChange,
      handleSearchClick,
      changeDialogVisible,
      ruleForm,
      COMPANY_ID,
      ...formState,
      staffGroupValue,
      UPLOAD_BASE_URL,
      handleCheckBoxChange,
      handleRemoveClick,
      handleSaveClick,
      currentOptStaffList,
      currentPage,
      handleSizeChange,
      handleCurrentChange,
      staffList,
      staffCopyList,
      jobLevel,
      handleStaffClick,
      searchValue,
      queryInfo

    }

  }
})
</script>

<style scoped lang="less">
.add-staff-dialog {
  font-size: 12px;

  .isActive {
    border: 2px solid var(--el-color-primary);
  }

  &:deep(.el-dialog__body) {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .search {
    height: 32px;
  }

  .tips {
    margin: 12px 0;
  }

  &:deep(.el-checkbox-group) {
    .el-checkbox-button__inner {
      border-left: 1px solid #dcdfe6;
    }

    .el-checkbox-button {
      height: 50px;
    }
  }

  &:deep(.el-select) {
    width: 100%;
  }

  .content {
    display: flex;
    // border-top: 1px solid var(--borderColor);
    // padding-top: 20px;

    .left-item {
      flex: 1;

      .form {
        display: flex;
        justify-content: space-between;

        &:deep(.el-select-1) {
          margin-right: 10px;
        }
      }

      .search {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;

        .search-btn {
          margin-left: 10px;
        }
      }

      .staff-list {
        margin-top: 20px;

        .staff-item {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 50px;
          line-height: 50px;
          padding: 0 10px;
          border-radius: 4px;
          margin-bottom: 10px;

          .left {
            display: flex;
            align-items: center;

            img {
              width: 30px;
              height: 30px;
              border-radius: 50%;
              margin-right: 10px;
            }
          }
        }

        &:deep(.el-checkbox-group) {
          .el-checkbox-button {
            width: 100%;
            margin-bottom: 10px;

            span {
              width: 100%;
            }
          }
        }
      }
    }

    .right-item {
      margin-left: 10px;
      padding-left: 10px;
      flex: 1;
      border-left: 1px solid var(--borderColor);

      .tips {
        line-height: 30px;
        background-color: rgb(239, 240, 240);
        font-size: 12px;
        padding: 0 10px;
      }

      .opt-staff-list {
        .opt-staff {
          display: flex;
          justify-content: space-between;
          margin: 6px 0;
          height: 40px;
          line-height: 40px;
          border-bottom: 1px solid rgb(229, 229, 229);

          .remove {
            color: rgb(102, 177, 255);
            font-size: 13px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
